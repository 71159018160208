.slider-intro {
    background: url('../images/slider/bg.jpg');
    background-size: cover;
    height: 100vh;
}

.slider-intro .caption {
    height: 100vh;
}

.otp_class {
    justify-content: space-around;
}

.panel--left.active {
    transform: translate3d(100%, 0, 0);
    transition-duration: 400ms;
}

.panel--right.active {
    transform: translate3d(-100%, 0, 0);
    transition-duration: 400ms;
}

.preloader {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999;
    opacity: 1;
    background: url('../images/logo/bg.webp');
    background-repeat: no-repeat;
    background-color: #FFF;
    background-position: center;
    transition: opacity 0.5s ease-in-out;
}

.fade-in {
    transition: opacity 1s ease;
}

.fade-out {
    opacity: 0;
    transition: opacity 1s ease;
}

.fieldset.custom-form {
    height: 75%;
    overflow-y: auto;
    margin-top: 3%;
}

.fadeout {
    opacity: 0;
    width: 0;
    height: 0;
    transition: width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.5s;
}

.fadein {
    opacity: 1;
    width: 100%;
    height: 100%;
    transition: width 0.5s, height 0.5s, opacity 0.5s 0.5s;
}

.modal {
    font-size: 12px;
}

.modal>.header {
    width: 100%;
    border-bottom: 1px solid gray;
    font-size: 18px;
    text-align: center;
    padding: 5px;
    color: #121330 !important;
}

.modal>.content {
    padding: 10px 5px;
    height: 400px;
    overflow-y: scroll;
}

.modal>.actions {
    width: 100%;
    padding: 10px 5px;
    margin: auto;
    text-align: center;
}

.modal>.close {
    cursor: pointer;
    position: absolute;
    display: block;
    padding: 10px;
    right: -10px;
    top: -10px;
    height: 10px;
    background: #ffffff;
    border-radius: 18px;
    border: 1px solid #cfcece;
}

.popup-overlay {
    z-index: 999999 !important;
}

.popup-content {
    width: 80% !important;
    padding: 20px;
}

.button-container {
    display: flex;
    justify-content: center;
}

.login-form {
    background-color: white;
    padding: 2rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 10px;
}

.list-container {
    display: flex;
}

.error {
    color: red;
    font-size: 12px;
}

.title {
    font-size: 25px;
    margin-bottom: 20px;
    text-align: center;
}

.input-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 10px;
}

.form-control {
    height: 30px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 8px;
}

.button-container input {
    margin-top: 10px;
    cursor: pointer;
    font-size: 15px;
    background: #251D67;
    border: 1px solid #01d28e;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
}

.app {
    font-family: sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    height: 100vh;
    background-color: #f8f9fd;
}

.react-tel-input .form-control {
    width: 100% !important;
}

.lds-dual-ring {
    display: flex;
    height: 80px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    justify-content: center;
    align-items: center;
    height: 100%;
    background: #f5f5f5bf;
}

.lds-dual-ring:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #fff;
    border-color: #000 transparent #000 transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@media only screen and (max-width: 600px) {
    .otp_class input {
        margin-right: 0px !important;
    }
    .otp_class input:last-child {
        margin: 0 !important;
    }
    .login-form {
        padding: 10px !important;
        width: 80% !important;
    }
}

.login_btn:disabled {
    opacity: 0.6;
}

.main-nav ul li i {
    display: block;
    max-width: 20px;
    margin-right: 24px;
    color: #C9C9C9;
}